import { withI18next } from '@tvai/gatsby-plugin-i18next';

import { graphql } from 'gatsby';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import FranchiseConfirm from '../components/Banners/FranchiseConfirm';
import KeepInTouch from '../components/Banners/KeepInTouch';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { SetupInfoProvider } from '../providers/setupInfoProvider';
import { checkLanguage } from '../utils/language';

class Page extends Component {
  componentDidMount() {
    checkLanguage(this.props.pageContext, this.props.location);
  }

  render() {
    const { t } = this.props;

    return (
      <SetupInfoProvider>
        <Layout navInvert noTopPadding>
          <SEO pageTitle={t(`meta_title_franchise`)} />

          <FranchiseConfirm />
          <KeepInTouch />
        </Layout>
      </SetupInfoProvider>
    );
  }
}

export default withI18next()(withTranslation()(Page));

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;
