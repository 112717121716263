import '../banners.less';
import './index.less';

import { Link } from '@tvai/gatsby-plugin-i18next';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from '../../BannerShared/Title';
import AppButton from '../../Button';

const FranchiseConfirm = () => {
  const { t } = useTranslation();

  return (
    <div className="franchise-confirm-wrapper">
      <div className="container">
        <Row type="flex" justify="center">
          <Col xs={18} sm={12} md={12} lg={12}>
            <Title title={t('franchise_confirm_tagline')} isTagline isCenter />
            <Title title={t('franchise_confirm_title')} description={t('franchise_confirm_desc')} />
            <Link to="/">
              <AppButton type="primary">{t('General_Back_To_Homepage')}</AppButton>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

FranchiseConfirm.propTypes = {
  t: PropTypes.func,
};

export default FranchiseConfirm;
